
.input-group {
    &-text{
        @include transition($input-transition);
    }
    &:focus-within {
        .input-group-text {
            border-color: var(--#{$variable-prefix}secondary-tint-40);
        }
    }
}
