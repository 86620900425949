
#lcchat
div[class^="ApplicationContainer"].overlay
div[class^="RecentContainer"] {
display: none;
}

#lcchat div[class^="ApplicationContainer"] {
  position: relative;
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-template-rows: 100%;
  height: 100vh;
  overflow: hidden;
  background: #fff;
  max-height: calc(100vh - 60px);
  margin-top:-1rem;
  line-height: 1.5;
  overflow: hidden;
}

@supports (height: 100dvh) {
  #lcchat div[class^="ApplicationContainer"] {
    height: calc(100dvh - 60px);
    max-height: calc(100dvh - 60px);
  }
}


#lcchat div[class^="ApplicationContainer"] > button {
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.8rem;
  right: 1rem;
  height: 1.8rem;
  width: 1.8rem;
  background: none;
  border: none;
  font-size: 1.5rem;
}

#lcchat div[class^="ApplicationContainer"] div[class^="RecentContainer"] {
  position: relative;
  z-index: 9;
}



#lcchat div[class^="RecentContainer"] {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #fff;
  padding-top: 0;
  /* display: none; */
}

#lcchat [class^="DetailsIcon"] {
  display: none;
}

#lcchat [class^="CreateNewChatIcon"] {
  display: none;
}

#lcchat div[class^="RecentContainer"] div[class^="RecentHeader"] {
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  margin: 0 1rem;
  padding: 0.8rem 0;
}

#lcchat div[class^="RecentContainer"] div[class^="RecentHeaderLabel"] {
  margin: 0;
}

#lcchat div[class^="RecentContainer"] input {
  display: none;
  width: 100%;
  padding: 0.6rem 1.2rem;
  border: 1px solid #ccd0d5;
  border-radius: 0.25rem;
  font-size: 1rem;
}

#lcchat div[class^="RecentContainer"] div[class^="InfiniteScrollContainer"] {
  flex: 1 1 auto;
  overflow: auto;
}

#lcchat div[class^="MessageWrapper"] {
  max-width: 80%;
}

#lcchat div[class^="ChannelContainer"] {
  display: flex;
  flex-direction: column;
}

#lcchat div[class^="ChannelContainer"] div[class^="ChatHeaderContainer"],
#lcchat
  div[class^="ChannelContainer"]
  div[class^="MessageComposeBarContainer"] {
  flex: 0 0 auto;
}

#lcchat div[class^="ChannelContainer"] div[class^="InfiniteScrollContainer"] {
  flex: 1 1 auto;
  overflow: auto;
}

#lcchat div[class^="ChannelContainer"] div[class^="ChatHeaderContainer"] {
  border-top: 0;
}

#lcchat div[class^="MessageListContainer"] {
  padding: 10px 10px 4px;
}

#lcchat div[class^="MessageContainer"] {
  min-width: 180px;
}

#lcchat div[class^="MemberCount"] {
  display: none;
}

#lcchat div[class^="Popover"] {
  padding-block: 0;
}

#lcchat div[class^="ChatItemLeft"] div[class^="Title"],
#lcchat div[class^="ChatItemLeft"] .Metadata,
#lcchat div[class^="ChatItemLeft"] .UnreadCount {
  display: none;
}
@media (min-width: 480px) {


    #lcchat div[class^="ApplicationContainer"] > button {
    display: none;
  }
  #lcchat div[class^="MessageWrapper"] {
    max-width: 60%;
  }

  #lcchat div[class^="RecentContainer"] {
    z-index: 1;
    position: relative;
    display: flex !important;
    width: auto;
    border-right: 1px solid #ccd0d5;
  }

  #lcchat div[class^="MessageListContainer"] {
    padding: 10px 20px 4px;
  }

  #lcchat div[class^="MessageContainer"] {
    min-width: 200px;
  }
}

@media (min-width: 720px) {
  #lcchat div[class^="ApplicationContainer"] {
    max-height: calc(100vh - 120px - 2rem);
    margin-top: 0;
    display: grid;
    grid-template-columns: 20rem 1fr;
    grid-template-rows: 100%;
    width: 100%;
  }

  #lcchat div[class^="RecentContainer"] input {
    display: block;
  }
  #lcchat div[class^="ChatItemLeft"] div[class^="Title"] {
    width: 13rem;
  }
  #lcchat div[class^="ChatItemLeft"] div[class^="Title"],
  #lcchat div[class^="ChatItemLeft"] .UnreadCount {
    display: block;
  }
  #lcchat div[class^="ChatItemLeft"] .Metadata {
    display: flex;
  }
}
