.profile-pic {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	object-position: center center;

}

div.profile-pic {
	display: flex;
	background-color: #ccc;
	align-items: center;
	justify-content: center;
	font-size: .8rem;
}
.profile-img-edit {
	position: relative;
	display: inline-block;
	height: 150px;
	width: 150px;
}
.file-upload {
	display: none;
}
.p-image {
	position: absolute;
	top: auto;
	right: 8px;
	bottom: 10px;
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	background: $primary;
	color: $white;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	line-height: 28px;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
	&:hover {
		background: var(--bs-primary-shade-20);
	}
}
.upload-button {
	font-size: 1.5em;
}
.iq-edit-profile {
	display: flex;
	margin-bottom: 1px;
	.nav-link {
		padding: 1em 1em;
		border-radius: 0;
		color: var(--iq-body-text);
		text-align: center;
		border-left: 1px solid var(--iq-light-primary);
		background-color: $white;
	}
}
.iq-edit-profile.nav-pills {
	.nav-link.active {
		color: $white;
		background: $primary;
	}
	.show {
		>.nav-link {
			color: $white;
			background: $primary;
		}
	}
}
ul.iq-edit-profile {
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	overflow-x: auto;
	display: flex;
	flex-wrap: nowrap;
	background-color: transparent;
	// margin-inline: -24px;
	// padding-inline: 24px;
	-webkit-overflow-scrollin: touch;
	scrollbar-width: none;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	&::-webkit-scrollbar {
		display: none;
	}
	li {
		&:first-child {
			a {
				border-left: none;
			}
		}
		&:last-child {
			a {
				// border-radius: 0 10px 0 0;
			}
		}
	}
}
