// Main

$body-bg: #1a1a1a;
$body-color: #c7c7c7;
$card-bg: #0f0f0f;
$border-color: #2a2a2a;
$dark: #60658a;
$theme-colors: map-merge($theme-colors,
  (
    "dark":       $dark
  )
);
$alert-bg-scale: 60%;
$alert-border-scale: 80%;
$alert-color-scale: 80%;