.iq-icons-list {
    line-height: 3.125rem;
    margin-bottom: 0.938rem;
    display: inline-block;
    
    i {
        text-align: center;
        vertical-align: middle;
        font-size: 1.125rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        margin-right: 0.625rem;
        display: inline-block;
    }
}

.writ-icon{
    transform: rotate(321deg);
}
.dropbtn{
    .color-icon{
        line-height: 2.5rem;
        }
}
.material-icons-outlined.md-14 { font-size: 14px; }
.material-icons-outlined.md-16 { font-size: 16px; }
.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-20 { font-size: 20px; }
.material-icons-outlined.md-32 { font-size: 32px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-42 { font-size: 42px; }
.material-icons-outlined.md-64 { font-size: 64px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-14 { font-size: 14px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
