.form-control {
    &:focus {
        border-color: var(--#{$variable-prefix}secondary-tint-40);
    }
}
.form-select {
    &:focus {
        border-color: var(--#{$variable-prefix}secondary-tint-40);
    }
}
